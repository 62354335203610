* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #fff !important;
}

.marquee {
  width: 100%;
  background-color: white;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
  border-radius: 6px;
}

.marquee p {
  display: inline-block;
  padding-left: 100%;
  animation: marquee 45s linear infinite;
}

.marqueeLandingPage {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
}

.marqueeLandingPage>div {
  display: inline-flex;
  /* padding-left: 100vw; */
  animation: marqueeLandingPage 80s linear infinite;
}

.hero_section_map .mapboxgl-ctrl-top-right {
  bottom: 22% !important;
  top: inherit !important;
  right: 20px !important;
}

@media (min-height:800px) {
  .hero_section_map .mapboxgl-ctrl-top-right {
    bottom: 22% !important;
  }
}
@media (min-height:1000px) {
  .hero_section_map .mapboxgl-ctrl-top-right {
    bottom: 18% !important;
  }
}
@media (min-height:1200px) {
  .hero_section_map .mapboxgl-ctrl-top-right {
    bottom: 15% !important;
  }
}

@keyframes marqueeLandingPage {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(-50%, 0);
  }
}

@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(-100%, 0);
  }
}



@keyframes development_float__UCD_H {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-20px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes floatUpDown {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-20px);
  }

  100% {
    transform: translateY(0);
  }
}

@media (max-width:1080px) {
  .floating_images {
    transform: translate(-50%, -50%);
    left: 50%;
    top: 100px;
  }
}

@media (max-width:600px) {
  .floating_images {
    transform: translate(-50%, -50%);
    /* left: -5%; */
    top: 100px;
  }
}





.mySwiper {
  width: 100%;
  position: relative;
}

/* 

.swiper-button-next{
  position: static !important;
  width: auto !important;
  height: auto !important;
  margin-top: 0 !important;
}

.swiper-button-next::after{
  position: static;
  content: none !important;
}

.swiper-button-prev{
  position: static !important;
  width: auto !important;
  height: auto !important;
  margin-top: 0 !important;
}

.swiper-button-prev::after{
  position: static;
  content: none !important;
} */


/* .testimonial_swiper_slide {
  width: 100% !important;
  display: flex !important;
  justify-content: space-between !important;
} */

.swiper-button-lock {
  display: block !important;
}

.shape {
  width: 140px;
  height: 140px;
}

.swiper-button-next {
  width: 40px !important;
  top: 80% !important;
  height: 40px !important;
  position: absolute !important;
  cursor: pointer !important;
}

.swiper-button-next svg {
  width: 20px !important;
}

.swiper-button-next::after {
  position: static;
  content: none !important;
}

.swiper-button-prev {
  width: 40px !important;
  height: 40px !important;
  right: 70px !important;
  left: inherit !important;
  top: 80% !important;
  position: absolute !important;
  cursor: pointer !important;
}

.swiper-button-prev::after {
  position: static;
  content: none !important;
}

.buttons {
  display: flex;
  justify-content: space-around;
  top: 20px;
  left: 20px;
}

.buttons button {
  position: relative;
  background-color: transparent;
  border: 0;
  font-weight: 600;
  overflow: hidden;
  transition: all 0.3s ease;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttons button p:before,
.buttons button p:after {
  position: absolute;
  width: 100%;
  transition: all 0.4s cubic-bezier(0.35, 0.1, 0.25, 1);
  z-index: 1;
  left: 0;
}

.buttons button p:before {
  content: attr(data-title);
  top: 50%;
  transform: translateY(-50%);
}

.buttons button p:after {
  content: attr(data-text);
  top: 150%;
}

.buttons button:hover p:before {
  top: -100%;
  transform: rotate(5deg);
}

.buttons button:hover p:after {
  top: 50%;
  transform: translateY(-50%);
}

.cta-button {
  display: flex;
  justify-content: space-around;
  position: relative;
  overflow: hidden;
}

.cta-button::before {
  content: "";
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 30px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: transform .3s ease-in-out;
  transform: translateX(-105%);
}

.cta-button:hover::before {
  transform: translateX(0);
}

.cta-button button {
  position: relative;
  background-color: transparent;
  border: 0;
  font-weight: 600;
  overflow: hidden;
  transition: all 0.3s ease;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cta-button button p:before,
.cta-button button p:after {
  position: absolute;
  width: 100%;
  transition: all 0.3s cubic-bezier(0.35, 0.1, 0.25, 1);
  z-index: 1;
  left: 0;
}

.cta-button button p:before {
  content: attr(data-title);
  top: 50%;
  transform: translateY(-50%);
}

.cta-button button p:after {
  content: attr(data-text);
  top: 150%;
}

.cta-button:hover p:before {
  top: -100%;
}

.cta-button:hover p:after {
  top: 50%;
  transform: translateY(-50%);
  color: #006BFF;
}

.btn-arrow {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.arrow {
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0;
  transform: translate(-60px, -50%);
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.learn-more-btn:hover .btn-arrow {
  transform: translate(40px, -50%);
  opacity: 0;
}

.learn-more-btn:hover .arrow {
  transform: translate(-50%, -50%);
  opacity: 1;
}

.figcard-icon {
  width: 100px;
  height: 100px;
  margin-top: 108px;
  margin-left: 18px;
}

.background-media {
  border-radius: inherit !important;
  position: relative !important;
  object-fit: cover !important;
  object-position: center !important;
  background-color: rgb(255 255 255 / 5%);
}

.sticky-image {
  flex-shrink: 0;
  min-width: 910px !important;
  width: 910px !important;
  height: 680px !important;
  position: relative !important;
  object-fit: cover;
  object-position: center top;
}

.marquee-gems {
  width: 60px;
  height: 60px;
}

.marquee-gems-glow {
  width: 70px;
  height: 70px;
}

.landing_video {
  position: absolute;
  position: absolute;
  z-index: 2;
  width: 348px;
  height: 741px;
  top: 56px;
  border-radius: 42px;
}

@media screen and (max-width: 1200px) {}

@media screen and (max-width: 900px) {
  .sticky-image {
    min-width: 639px !important;
    width: 639px !important;
    height: 576.64px !important;
  }

  .marquee-gems {
    width: 44px;
    height: 44px;
  }

  .marquee-gems-glow {
    width: 50px;
    height: 50px;
  }
}

@media screen and (max-width: 600px) {
  .shape {
    width: 80px;
    height: 80px;
  }

  .sticky-image {
    min-width: 475.39px !important;
    width: 475.39px !important;
    height: 429px !important;
  }

  .marquee-gems {
    width: 35px;
    height: 35px;
  }

  .marquee-gems-glow {
    width: 40px;
    height: 40px;
  }

  .figcard-icon {
    width: 100px;
    height: 100px;
  }
}


@media (max-width:600px) {
  .testimonial_swiper_slide {
    bottom: -40px;
  }
}