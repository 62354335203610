.map-container {
  height: 400px;
}

.marker {
  display: block;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  padding: 0;
}

.mapboxgl-canvas {
  width: 100% !important;
}

.mapboxgl-ctrl-logo {
  display: none !important;
}

.ck-balloon-panel {
  display: none !important;
}

.ck-editor__editable {
  min-height: 500px !important;
}

.ck.ck-editor__editable_inline {
  padding: 0 20px !important;
}

.blockbuilder-preview {
  padding: 0;
}

.MuiDialog-paper {
  min-width: 400px !important;
}

.MuiDateTimePickerToolbar {
  align-items: center !important;
}

.MuiDateTimePickerToolbar-timeDigitsContainer {
  align-items: center !important;
}

.MuiDateTimePickerToolbar-ampmSelection {
  flex-direction: row !important;
  gap: 10px !important;
}

.MuiDateCalendar-root {
  width: 100% !important;
}

.fr-box.fr-document .fr-wrapper {
  min-width: 100%;
  padding: 20px;
  max-height: 400px !important;
}

.fr-box.fr-document .fr-wrapper .fr-element {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  min-height: 350px !important;
}

.rdw-colorpicker-modal{
  left: -140px !important;
}
.demo-wrapper{
  border: 1px groove #c4c4c4;
}
.demo-editor{
  padding: 8px 12px ;
}